
<div class="testimonials-w-container">
    <div class="testimonials-container text-center d-flex flex-column justify-content-center align-items-center pb-5">
        <h2 class="title mb-2">¿Por qué confiar en nosotros?</h2>
        <p class="sub-title w-75">
            Contamos con más de <strong style="color: #ff8f1d;">13 años en el mercado</strong>, operando con la solidez de Banco Azteca, hemos atendido a casi <strong style="color: #ff8f1d;">6 millones de clientes</strong> en más de <strong style="color: #ff8f1d;">1,100 sucursales</strong> de todo el país, abriendo los <strong style="color: #ff8f1d;">365 días del año</strong> en un amplio horario.
            <br>
            Nuestros clientes son nuestro mejor respaldo.
        </p>
        <div class="testimonials slider-blog mb-5">
            <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings">
                <div class="testimonial slide-content mb-5" v-for="(testimonial, index) in testimonialesData"
                     :key="index">
                    <div class="testimonial-media">
                        <img loading="lazy" width="200" height="200" v-if="testimonial.values.asset_testimonio.value"
                        :src="testimonial.values.asset_testimonio.value.public_path"
                        :alt="testimonial.values.asset_testimonio.value.alt" />
                        <div class="h-100 d-flex align-items-center" :style="'text-align: initial;'" v-else>
                            <lite-vimeo :videoid="testimonial.values.url_video.value" videotitle="This is a video title"></lite-vimeo>
                        </div>
                    </div>
                    <div class="testimonio">
                        <p>
                            <img v-img-prefix class="comillas mt-4" :src="`/public-assets/comillas-azules`"
                                 alt="comillas azules icono" width="30" height="28"/>
                        </p>
                        <p class="sub-title my-4">
                            {{ testimonial.values.testimonio.value }}
                        </p>
                    </div>
                    <div class="client-info">
                        <p class="m-auto"><strong>{{ testimonial.values.nombre_cliente.value }}</strong></p>
                        <span class="sub-title m-auto pb-5"><em>{{ testimonial.values.ocupacion.value }}</em></span>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
    </div>
</div>
